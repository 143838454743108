import React, { useRef } from "react";
import { useState } from "react";
import { __ } from "../lang/setup";
import picture from "../assets/imgs/pic.png";
import devrookvid from "../assets/videos/devrook.mp4";
import upflowvid from "../assets/videos/upflow.mp4";
import { GiCheckMark } from "react-icons/gi";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { AnimatePresence, motion } from "framer-motion";
import { RiVideoAddFill } from "react-icons/ri";
import { FaGithub } from "react-icons/fa";
import { RiExternalLinkFill } from "react-icons/ri";
import {
  BsLinkedin,
  BsStackOverflow,
  BsGithub,
  BsTwitterX,
} from "react-icons/bs";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

const Home = () => {
  const projectRef = useRef();
  const articleRef = useRef();
  const connectRef = useRef();
  return (
    <div className="text-white">
      <div className="fixed top-0 w-full z-50 bg-gray-950">
        <div className="w-full h-24 container mx-auto p-4">
          <div className="flex justify-between items-center h-full">
            <div className="font-mono text-2xl font-medium">GM1957</div>
            <div className="flex gap-8">
              <div
                onClick={() => {
                  articleRef.current.scrollIntoView();
                }}
                className="cursor-pointer"
              >
                Articles
              </div>
              <div
                onClick={() => {
                  projectRef.current.scrollIntoView();
                }}
                className="cursor-pointer"
              >
                Projects
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full mt-24 container mx-auto min-h-[70vh] flex items-center flex-wrap-reverse">
        <div className="flex-1 p-4 fade-in-down">
          <div
            className="text-[80px] font-semibold"
            style={{ fontFamily: "'Inter', sans-serif" }}
          >
            Hi, I'm Tanmoy!
          </div>
          <div
            className="text-[40px] my-4"
            style={{ fontFamily: "'Varela Round', sans-serif" }}
          >
            I <span className="text-blue-700">build</span> custom WEB solutions,{" "}
            <span className="text-blue-700"> write </span> about DEV and{" "}
            <span className="text-blue-700">fix </span>
            machines as a hobby.
          </div>

          <div
            className="flex text-2xl mt-8 flex-wrap gap-7"
            style={{ fontFamily: "'Varela Round', sans-serif" }}
          >
            <button
              onClick={() => {
                projectRef.current.scrollIntoView();
              }}
              className=" transition duration-300 ease-in-out transform bg-blue-800 hover:bg-blue-700 text-white py-3 w-64 rounded focus:outline-none focus:shadow-outline-blue active:bg-blue-800 hover:scale-105"
            >
              My Projects
            </button>
            <button
              onClick={() => {
                connectRef.current.scrollIntoView();
              }}
              className="transition duration-300 ease-in-out transform bg-gray-800 hover:bg-gray-700 text-white py-3 w-64 rounded focus:outline-none focus:shadow-outline-gray active:bg-gray-900 hover:scale-105"
            >
              Get in Touch
            </button>
          </div>
        </div>
        <div className="flex justify-center flex-1 p-4">
          <img
            fetchpriority="high"
            src={picture}
            className="sm:h-[400px] sm:min-w-[400px] md:h-[400px] md:min-w-[400px] lg:h-[400px] lg:min-w-[400px] xl:h-[400px] xl:min-w-[400px] 2xl:h-[400px] 2xl:min-w-[400px] h-[300px] min-w-[300px] rounded-full border-8 border-blue-700 fade-in-down"
          />
        </div>
      </div>
      <div className="w-full mt-24 container mx-auto p-4">
        <div className="flex justify-center">
          <div className="h-16 w-9 rounded-3xl border-2 p-2 border-blue-800 flex justify-center items-center">
            <div className="h-4 w-4 scroll-down bg-blue-800 rounded-full"></div>
          </div>
        </div>

        <h1 className="text-6xl font-bold text-center text-white relative z-20 mt-40">
          Innovative Projects
        </h1>
        <div className="flex justify-center mt-5">
          <div className="w-[40rem] h-10 relative">
            <div className="absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-[2px] w-3/4 blur-sm" />
            <div className="absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-px w-3/4" />
            <div className="absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-[5px] w-1/4 blur-sm" />
            <div className="absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-px w-1/4" />
            <div className="absolute inset-0 w-full h-full bg-gray-950 [mask-image:radial-gradient(350px_200px_at_top,transparent_20%,white)]"></div>
          </div>
        </div>
        <div className="flex flex-col gap-28 mb-40 mt-24">
          <div className="flex flex-wrap justify-between gap-8">
            <video
              width="600"
              autoPlay="autoplay"
              id="vid"
              muted
              loop
              className="rounded-3xl"
            >
              <source src={devrookvid} type="video/mp4" />
            </video>

            <div className="w-full max-w-[600px]">
              <div className="flex flex-col justify-between h-full">
                <div className="text-xl mb-5">
                  <span className="font-bold text-4xl">DevRook </span> -
                  Developer's Social Media
                </div>
                <div className="font-bold grid grid-cols-2 gap-4">
                  <div className="flex items-center">
                    <GiCheckMark className="text-blue-700 mr-2" />{" "}
                    <p>Personalized Feed</p>
                  </div>
                  <div className="flex items-center">
                    <GiCheckMark className="text-blue-700 mr-2" />{" "}
                    <p>User Ranking By Reputation</p>
                  </div>

                  <div className="flex items-center">
                    <GiCheckMark className="text-blue-700 mr-2" />{" "}
                    <p>Live Messaging</p>
                  </div>
                  <div className="flex items-center">
                    <GiCheckMark className="text-blue-700 mr-2" />{" "}
                    <p>Post Questions / Answers / Blog</p>
                  </div>

                  <div className="flex items-center">
                    <GiCheckMark className="text-blue-700 mr-2" />{" "}
                    <p>Follow / Unfollow Users or Tags </p>
                  </div>
                  <div className="flex items-center">
                    <GiCheckMark className="text-blue-700 mr-2" />{" "}
                    <p>Upvote / Downvote / Like</p>
                  </div>

                  <div className="flex items-center">
                    <GiCheckMark className="text-blue-700 mr-2" />{" "}
                    <p>Image Upload</p>
                  </div>
                  <div className="flex items-center">
                    <GiCheckMark className="text-blue-700 mr-2" />{" "}
                    <p>Login and Signup</p>
                  </div>
                  <div className="flex items-center">
                    <GiCheckMark className="text-blue-700 mr-2" />{" "}
                    <p>Trending Posts / Users</p>
                  </div>
                  <div className="flex items-center">
                    <GiCheckMark className="text-blue-700 mr-2" />{" "}
                    <p>Search Posts / Users</p>
                  </div>
                  <div className="flex items-center">
                    <GiCheckMark className="text-blue-700 mr-2" />{" "}
                    <p>Answers Delete Protection</p>
                  </div>
                  <div className="flex items-center">
                    <GiCheckMark className="text-blue-700 mr-2" />{" "}
                    <p>Edit Profile</p>
                  </div>
                </div>
                <div className="flex justify-evenly font-bold text-lg mt-2 flex-wrap gap-3">
                  <div
                    className="flex items-center px-3 py-1 rounded-lg cursor-pointer"
                    style={{ border: "1px solid" }}
                    onClick={(e) => {
                      window.open("https://github.com/GM1957/devrook-FE");
                    }}
                  >
                    <FaGithub className="mr-2" /> Source
                  </div>
                  <div
                    className="flex items-center px-3 py-1 rounded-lg cursor-pointer"
                    style={{ border: "1px solid" }}
                    onClick={(e) => {
                      window.open("https://devrook.gm1957.site/");
                    }}
                  >
                    <RiExternalLinkFill className="mr-2" /> Live
                  </div>
                  <div
                    className="flex items-center px-3 py-1 rounded-lg cursor-pointer"
                    style={{ border: "1px solid" }}
                    onClick={(e) => {
                      window.open(
                        "https://www.linkedin.com/posts/gm1957_opensource-contribute-socialmedia-activity-6810467326295584768-OapI"
                      );
                    }}
                  >
                    <RiVideoAddFill className="mr-2" /> Demo
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap-reverse gap-8 justify-between">
            <div className="w-full max-w-[600px]">
              <div className="flex flex-col justify-between h-full">
                <div className="text-xl mb-5">
                  <span className="font-semibold text-4xl">Upflow </span> - The
                  Project Manager
                </div>
                <div className="font-bold grid grid-cols-2 gap-4">
                  <div className="flex items-center">
                    <GiCheckMark className="text-blue-700 mr-2" />{" "}
                    <p>Kanban Board</p>
                  </div>
                  <div className="flex items-center">
                    <GiCheckMark className="text-blue-700 mr-2" />{" "}
                    <p>Assign to Users</p>
                  </div>

                  <div className="flex items-center">
                    <GiCheckMark className="text-blue-700 mr-2" />{" "}
                    <p>Edit/Add Columns</p>
                  </div>
                  <div className="flex items-center">
                    <GiCheckMark className="text-blue-700 mr-2" />{" "}
                    <p>Role Based Access Control</p>
                  </div>

                  <div className="flex items-center">
                    <GiCheckMark className="text-blue-700 mr-2" />{" "}
                    <p>Invite Members to Project</p>
                  </div>

                  <div className="flex items-center">
                    <GiCheckMark className="text-blue-700 mr-2" />{" "}
                    <p>Image Upload</p>
                  </div>
                  <div className="flex items-center">
                    <GiCheckMark className="text-blue-700 mr-2" />{" "}
                    <p>Login and Signup</p>
                  </div>
                </div>
                <div className="flex justify-evenly font-bold text-lg mt-2 flex-wrap gap-3">
                  <div
                    className="flex items-center px-3 py-1 rounded-lg cursor-pointer"
                    style={{ border: "1px solid" }}
                    onClick={(e) => {
                      window.open("https://github.com/GM1957/UpFlow-FE");
                    }}
                  >
                    <FaGithub className="mr-2" /> Source
                  </div>
                  <div
                    className="flex items-center px-3 py-1 rounded-lg cursor-pointer"
                    style={{ border: "1px solid" }}
                    onClick={(e) => {
                      window.open("https://upflow.gm1957.site/");
                    }}
                  >
                    <RiExternalLinkFill className="mr-2" /> Live
                  </div>
                  <div
                    className="flex items-center px-3 py-1 rounded-lg cursor-pointer"
                    style={{ border: "1px solid" }}
                    onClick={(e) => {
                      window.open(
                        "https://www.linkedin.com/posts/gm1957_serverless-aws-kanbanboard-activity-6811547782902505472-Qlzf?utm_source=share"
                      );
                    }}
                  >
                    <RiVideoAddFill className="mr-2" /> Demo
                  </div>
                </div>
              </div>
            </div>
            <div>
              <video
                width="600"
                autoPlay="autoplay"
                id="vid"
                muted
                loop
                className="rounded-3xl"
              >
                <source src={upflowvid} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
        <h1 className="text-7xl font-bold text-center text-white relative z-20">
          Articles
        </h1>
        <div className="flex justify-center mt-5">
          <div className="w-[40rem] h-10 relative">
            <div className="absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-[2px] w-3/4 blur-sm" />
            <div className="absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-px w-3/4" />
            <div className="absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-[5px] w-1/4 blur-sm" />
            <div className="absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-px w-1/4" />
            <div className="absolute inset-0 w-full h-full bg-gray-950 [mask-image:radial-gradient(350px_200px_at_top,transparent_20%,white)]"></div>
          </div>
        </div>
        <HoverEffect
          className="group dark"
          items={[
            {
              link: "https://dev.to/gm1957/how-can-you-upload-an-image-in-aws-s3-bucket-directly-with-presigned-url-2d5f",
              title: "Uploading to S3 Bucket with Presigned URL",
              description:
                "How can you upload an image in AWS S3 bucket directly with presigned URL",
            },
            {
              link: "https://www.linkedin.com/posts/gm1957_learning-help-share-activity-6775713406373638144-IL-r?utm_source=share",
              title: "'this' keyowrd in javascript",
              description:
                "Understanding how 'this' keyword works in javascript",
            },
            {
              link: "https://www.linkedin.com/posts/gm1957_deploy-your-nextjs-application-like-a-pro-activity-6971409406148132864-PIL4?utm_source=share",
              title: "Ways to deploy NextJS application",
              description:
                "Right way to deploy NextJS like applications with SSR capabilities to save cost and boost performance",
            },
          ]}
        />
      </div>

      <div className="relative w-[100svw] overflow-hidden h-[1000px] mt-[-260px] ">
        <div
          className="flex w-full h-full justify-center items-center text-3xl font-bold flex-col"
          ref={connectRef}
        >
          <div className={cn("text-7xl text-white relative z-20 pt-52")}>
            Get In Touch
          </div>
          <div className="flex flex-wrap gap-20 rounded-2xl h-fit w-fit p-12 overflow-hidden bg-black border dark:border-white/[0.2] border-slate-700 relative z-20 mt-20">
            <a
              className="hover:bg-slate-700 cursor-pointer p-5 rounded-full"
              target="_blank"
              href="https://www.linkedin.com/in/gm1957/"
            >
              <BsLinkedin className="text-5xl" />
            </a>
            <a
              className="hover:bg-slate-700 cursor-pointer p-5 rounded-full"
              target="_blank"
              href="https://stackoverflow.com/users/11700077/tanmoy-bhowmick"
            >
              <BsStackOverflow className="text-5xl" />{" "}
            </a>
            <a
              className="hover:bg-slate-700 cursor-pointer p-5 rounded-full"
              target="_blank"
              href="https://github.com/GM1957"
            >
              <BsGithub className="text-5xl" />
            </a>
            <a
              className="hover:bg-slate-700 cursor-pointer p-5 rounded-full"
              target="_blank"
              href="https://twitter.com/GrandMaster1957"
            >
              <BsTwitterX className="text-5xl" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

export const HoverEffect = ({ items, className }) => {
  let [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div
      className={cn(
        "grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3  py-10",
        className
      )}
    >
      {items.map((item, idx) => (
        <a
          href={item?.link}
          target="_blank"
          key={item?.link}
          className="relative group block p-2 h-full w-full"
          onMouseEnter={() => setHoveredIndex(idx)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <AnimatePresence>
            {hoveredIndex === idx && (
              <motion.span
                className="absolute inset-0 h-full w-full bg-neutral-200 dark:bg-slate-800/[0.8] block  rounded-3xl"
                layoutId="hoverBackground"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 0.15 },
                }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.15, delay: 0.2 },
                }}
              />
            )}
          </AnimatePresence>
          <Card>
            <CardTitle>{item.title}</CardTitle>
            <CardDescription>{item.description}</CardDescription>
            <div className="flex justify-between mt-10">
              <div className="px-4 py-1 border rounded">Read More</div>
            </div>
          </Card>
        </a>
      ))}
    </div>
  );
};

export const Card = ({ className, children }) => {
  return (
    <div
      className={cn(
        "rounded-2xl h-full w-full p-4 overflow-hidden bg-black border border-transparent dark:border-white/[0.2] group-hover:border-slate-700 relative z-20",
        className
      )}
    >
      <div className="relative z-50">
        <div className="p-4">{children}</div>
      </div>
    </div>
  );
};

export const CardTitle = ({ className, children }) => {
  return (
    <h4 className={cn("text-zinc-100 font-bold tracking-wide mt-4", className)}>
      {children}
    </h4>
  );
};
export const CardDescription = ({ className, children }) => {
  return (
    <p
      className={cn(
        "mt-8 text-zinc-400 tracking-wide leading-relaxed text-sm",
        className
      )}
    >
      {children}
    </p>
  );
};
